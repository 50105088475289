// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/mnt/c/Users/Trevor/Documents/Clients/Spinturnix/Site/gatsby-site/spinturnix18/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/mnt/c/Users/Trevor/Documents/Clients/Spinturnix/Site/gatsby-site/spinturnix18/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-project-js": () => import("/mnt/c/Users/Trevor/Documents/Clients/Spinturnix/Site/gatsby-site/spinturnix18/src/pages/projects/project.js" /* webpackChunkName: "component---src-pages-projects-project-js" */),
  "component---src-pages-samples-js": () => import("/mnt/c/Users/Trevor/Documents/Clients/Spinturnix/Site/gatsby-site/spinturnix18/src/pages/samples.js" /* webpackChunkName: "component---src-pages-samples-js" */),
  "component---src-pages-services-js": () => import("/mnt/c/Users/Trevor/Documents/Clients/Spinturnix/Site/gatsby-site/spinturnix18/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-shop-endure-js": () => import("/mnt/c/Users/Trevor/Documents/Clients/Spinturnix/Site/gatsby-site/spinturnix18/src/pages/shop/endure.js" /* webpackChunkName: "component---src-pages-shop-endure-js" */),
  "component---src-pages-shop-noma-js": () => import("/mnt/c/Users/Trevor/Documents/Clients/Spinturnix/Site/gatsby-site/spinturnix18/src/pages/shop/noma.js" /* webpackChunkName: "component---src-pages-shop-noma-js" */),
  "component---src-pages-shop-vectorglitch-js": () => import("/mnt/c/Users/Trevor/Documents/Clients/Spinturnix/Site/gatsby-site/spinturnix18/src/pages/shop/vectorglitch.js" /* webpackChunkName: "component---src-pages-shop-vectorglitch-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/mnt/c/Users/Trevor/Documents/Clients/Spinturnix/Site/gatsby-site/spinturnix18/.cache/data.json")

